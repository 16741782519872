import { mountURL } from '@/utils/sharedHelpers'

export default async function ({ isHMR, store, route, redirect, error, params }) {
  if (isHMR) return

  const { locale } = params
  const pathSegments = route.path.split('/').filter(Boolean)

  // Find the index of the locale in the path
  const localeIndex = pathSegments.findIndex(segment => segment === locale)
  if (localeIndex === -1) {
    // Locale not found in URL, ignore this route
    return
  }

  // Get the URL parameters
  const urlParams = getUrlParams(pathSegments, localeIndex)

  if (!urlParams) {
    // Not a handled segment, ignore
    return
  }

  const { id, segment } = urlParams

  // Proceed only if 'id' is present
  if (!id) {
    return error({ statusCode: 404, message: 'This page could not be found' })
  }

  try {
    let data
    let expectedUrl

    if (segment === 'article') {
      await store.dispatch('article/fetchArticle', id)
      data = store.getters['article/currentArticle']

      if ((!data || !data.id) && locale !== LANGUAGE.ENGLISH) {
        return redirect(301, mountURL('article', id, null, LANGUAGE.ENGLISH))
      }

      if (!data || !data.id) {
        return error({ statusCode: 404, message: 'Article not found' })
      }

      expectedUrl = mountURL('article', data.id, data.title, locale)
    } else if (segment === 'section') {
      await store.dispatch('section/fetchSection', id)
      data = store.getters['section/currentSection']

      if (!data || !data.id) {
        return error({ statusCode: 404, message: 'Section not found' })
      }

      expectedUrl = mountURL('section', data.id, data.name, locale)
    } else if (segment === 'categories') {
      await store.dispatch('categories/fetchCategory', id)
      data = store.getters['categories/currentCategory']

      if (!data || !data.id) {
        return error({ statusCode: 404, message: 'Category not found' })
      }

      expectedUrl = mountURL('categories', data.id, data.name, locale)
    }

    // Compare only the path without query strings
    if (normalizePath(route.path) !== normalizePath(expectedUrl)) {
      // Serialize the original query parameters
      const query = route.query
      const queryString = serializeQuery(query)

      // Construct the redirect URL by appending the original query string
      const redirectUrl = `${expectedUrl}${queryString}`

      return redirect(301, redirectUrl)
    }
  } catch (err) {
    //console.error('Middleware Error:', err)
    return error({ statusCode: 500, message: 'Internal Server Error' })
  }
}

/**
 * Helper function to extract URL parameters.
 *
 * @param {Array} pathSegments - The segments of the URL path.
 * @param {number} localeIndex - The index of the locale segment.
 * @returns {Object|null} - An object containing id, slug, and segment or null if not applicable.
 */
function getUrlParams(pathSegments, localeIndex) {
  const idRegex = /^\d+$/ // Regular expression to match numeric IDs
  const handledSegments = ['article', 'section', 'categories']

  const segmentIndex = localeIndex + 1
  const segment = pathSegments[segmentIndex]

  if (!segment || !handledSegments.includes(segment)) {
    return null
  }

  // Collect segments after the segment
  const paramsSegments = pathSegments.slice(segmentIndex + 1)

  let id = null
  let slug = null

  for (const segment of paramsSegments) {
    if (idRegex.test(segment)) {
      id = segment
    } else {
      slug = segment
    }
  }

  return { id, slug, segment }
}

/**
 * Helper function to serialize query parameters into a query string.
 *
 * @param {Object} query - The query parameters object.
 * @returns {string} - The serialized query string, including the leading '?' if applicable.
 */
function serializeQuery(query) {
  const keys = Object.keys(query)
  if (keys.length === 0) return ''

  const searchParams = new URLSearchParams()
  keys.forEach(key => {
    const value = query[key]
    if (Array.isArray(value)) {
      value.forEach(val => searchParams.append(key, val))
    } else if (value !== undefined && value !== null) {
      searchParams.append(key, value)
    }
  })

  const queryString = searchParams.toString()
  return queryString ? `?${queryString}` : ''
}

/**
 * Helper function to normalize paths by removing trailing slashes.
 *
 * @param {string} path - The URL path.
 * @returns {string} - The normalized path without trailing slashes.
 */
function normalizePath(path) {
  return path.endsWith('/') && path.length > 1 ? path.slice(0, -1) : path
}
